import React from "react"
import WalkImg from "../components/itemimg"
import Layout from "../components/layout"
import SEO from "../components/seo-home"
import "../styles/c404.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div class="img">
      <h2>PAGE NOT FOUND !!!</h2>
      <WalkImg
        filename="404.jpg"
        alt="Jeune chat affamé qui regarde une mouette"
      />
      <p>Vous venez d'essayer d'atteindre une page qui n'existe pas encore !</p>
      <p>...</p>
    </div>
  </Layout>
)

export default NotFoundPage
